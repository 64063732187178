<template>
  <div>
    <v-container>
      <v-row>


        <v-col cols="12" sm="8" md="8">

          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('administrationOffice')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
                v-for="(d,i) in infoList"
                :key="i"
                dark
                large
            >
              <template v-slot:icon>
                <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                  <img v-if="d.url" :src="d.url">
                </v-avatar>
              </template>
              <template v-slot:opposite>
                <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
              </template>
              <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
                <v-card-title class="text-h5">
                  <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

                </v-card-title>
                <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px">{{ $t(d.name) }}</span>

                <v-divider inset style="color: green"></v-divider>

                <v-card-text style="text-align: left">
                  <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                  <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                  <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                  <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                  <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                  <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>

        <v-col cols="12" sm="4" md="4" v-if="!$vuetify.breakpoint.mobile">
          <side-bar-event></side-bar-event>
          <media></media>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SideBarEvent from "@/components/sidebarEvent";
import Media from "@/components/sidebarMedia";

export default {
  name: 'AdministrationOffice',
  components: {Media,SideBarEvent},
  data(){
    return{
        infoList:[
          {
            name:"khut_sokun",
            position:"chief",
            phoneNumber:"+855-17 480 762",
            bachelor:"electronic_engineering",
            master:"",
            phd:"",
            email:"sokunkhut19@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fadmin%2Fsokun%20-%20sokun%20khut-min.png?alt=media&token=ca53f776-4b85-4eeb-ac2c-f075134f9591"
          },
          {
            name:"khem_sokheng",
            position:"deputyChief",
            phoneNumber:"+855-17 446 848",
            bachelor:"agronomy",
            master:"sustainable_agriculture",
            phd:"",
            email:"sokhengrpisb@gamil.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fadmin%2F142007745_3736894479705620_678114146893649306_n%20-%20sokheng%20khem.jpg?alt=media&token=7a43103b-7752-46db-a038-02d1191db76c"
          },
          {
            name:"chan_kakada",
            position:"deputyChief",
            phoneNumber:"+855-15 476 987",
            bachelor:"business_administration",
            master:"business_administration",
            phd:"",
            email:"kakadachan015@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fadmin%2FIMG_20210901_185404_107%20-%20kakada%20chan.jpg?alt=media&token=821da4fb-e1b2-45d9-b4d8-24ca04e1bcbc"
          },
          {
            name:"ruos_rithea",
            position:"deputyChief",
            phoneNumber:"+855-96 633 3323",
            bachelor:"math",
            master:"",
            phd:"",
            email:"rousrithea168@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fadmin%2FIMG_20210316_125724_796%20-%20Som%20Na.jpg?alt=media&token=2050ee09-9968-400f-b2ed-3c5641c9ebae"
          },
          {
            name:"chheuy_sokhom",
            position:"deputyChief",
            phoneNumber:"+855-16 613 651",
            bachelor:"electricity",
            master:"",
            phd:"",
            email:"chheuysokhom@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fadmin%2FC87C0C7D-2D1F-40BA-B677-8075504EA4F8%20-%209999%20chheuy-min.jpeg?alt=media&token=4320a279-feca-4265-a2ca-20e1bc9dcba1"
          },
          {
            name:"khat_pheap",
            position:"deputyChief",
            phoneNumber:"+855-",
            bachelor:"it",
            master:"",
            phd:"",
            email:"",
            url:""
          },
          {
            name:"thy_kamsan",
            position:"technicalTeacher",
            phoneNumber:"+855-98 414 000",
            bachelor:"electronic",
            master:"",
            phd:"",
            email:"kamsanthy9@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fadmin%2F%E1%9E%9A%E1%9E%BC%E1%9E%94%E1%9E%80%E1%9E%B6%E1%9E%8F%20-%20Kam%20San%20(1)-min.png?alt=media&token=075ea903-3c1b-4692-a629-3422221e6397"
          },
          {
            name:"pat_vuthy",
            position:"technicalTeacher",
            phoneNumber:"+855-12/70 890 032",
            bachelor:"engineering_in_electrical_and_electronics",
            master:"",
            phd:"",
            email:"patvuthy@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fadmin%2FDSC_0558%20copy%20-%20pat%20vuthy.jpg?alt=media&token=9987a483-2262-4703-bbc2-a3fa9766ef18"
          },
          {
            name:"yin_sros",
            position:"technicalTeacher",
            phoneNumber:"+855-16 614 306",
            bachelor:"",
            associate:"electricity",
            master:"",
            phd:"",
            email:"yinsros@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fadmin%2FB5A04FB4-E9FC-46BF-9CC1-4FD0E453FC59%20-%20Yin%20Sros.jpeg?alt=media&token=0107eb95-4c92-49d3-88d4-2d7610f961f1"
          },

          {
            name:"chheourn_kea",
            position:"technicalTeacher",
            phoneNumber:"+855-",
            bachelor:"",
            master:"",
            phd:"",
            email:"",
            url:""
          },

          {
            name:"roueth_chandara",
            position:"technicalTeacher",
            phoneNumber:"+855-69 838 179",
            bachelor:"bachelor_of_technology",
            master:"",
            phd:"",
            email:"roueth_chandara@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fadmin%2FIMG_20210907_090055_256%20-%20Roueth%20Chndara.jpg?alt=media&token=b6b0c21b-5049-4620-9933-f3f2e0a791b0"
          },

        ]

    }
  }
}
</script>
